import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k3xx.jpg'
import image_product_mobile from '../../../images/product/k3xx_mobile.jpg'

import substratelabels from "../../../content/substratelabels.json"

class K3xxPage extends Component {

	render() {
		return (
		  <Layout mobileTitle={true} 
		  		customClass='product-title'
		  		activePath='product/flooring'
		  		pageTitle="Konstrukt&trade; Tileworks&trade; K-321 Tile Adhesion Promoter and K-302 All-Purpose Tile Adhesive">
		  	<ProductPage 
		  		pageTitle="Konstrukt&trade; Tileworks&trade; K-321 Tile Adhesion Promoter and K-302 All-Purpose Tile Adhesive"
		  		productID={"80"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
							"key":"fitr",
		  					"name":substratelabels['fitr']
		  				},
		  				{
		  					"code":1,
							"key":"fihn",
		  					"name":substratelabels['fihn']
		  				},
		  				{
		  					"code":2,
							"key":"wihn",
		  					"name":substratelabels['wihn']
		  				},
		  				{
		  					"code":3,
							"key":"fitn",
		  					"name":substratelabels['fitn']
		  				},
		  				{
		  					"code":4,
							"key":"witn",
		  					"name":substratelabels['witn']
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K3xxPage
